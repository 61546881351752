.socials-container {
    width: 100%;
}

.social-links, .social-link {
    display: flex;
    gap: 50px;
    justify-content: space-evenly;
    align-items: center;
}

.social-link {
    font-family: "Montserrat", serif;
    flex-direction: column;
    gap: 10px;
}