.product-item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
}

.products-sidenav {
    width: 200px;
}

.products-sidenav li {
    border-bottom: 1px solid #ccc;
    padding: 4px 0;
    margin: 4px 0;
    white-space: nowrap;
}

.products-sidenav li.active a {
    color: #ffcc00
}

.products-sidenav li:last-of-type {
    border-bottom-width: 0;
}

.product-content-container {
    flex-grow: 1;
}

.product-examples {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 70%;
    margin: 30px 0 20px;
    padding: 40px;
}

.product-example {
    display: flex;
    gap: 20px;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 5px 5px rgba(204,204,204,0.4);
    -moz-box-shadow: 0px 0px 5px 5px rgba(204,204,204,0.4);
    box-shadow: 0px 0px 5px 5px rgba(204,204,204,0.4);
    padding: 20px;
    background-color: #fcfcf7;
}


.product-description { 
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.left .product-description { 
    justify-content: flex-end;
    text-align: right;
}

.left .back-button-wrapper {
    display: flex;
    justify-content: right;
}

.right .product-description { 
    justify-content: flex-start;
    text-align: left;
}

.product-image {
    background-color: #150e14;
    height: 400px;
    width: 400px;
    min-width: 400px;
    border-radius: 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.cricut {
    background-image: url('/public/img/product-types/cricut-designs/spotlight/spotlight-1.jpg');
}

.crochet {
    background-image: url('/public/img/product-types/crochet-designs/spotlight/spotlight-2.jpg');
}

.custom {
    background-image: url('/public/img/product-types/laser-designs/spotlight/spotlight-2.jpg');
}

.door-hangers-wreaths {
    background-image: url('/public/img/product-types/door-hangers-and-wreaths/spotlight/spotlight-1.jpg');
}

.laser {
    background-image: url('/public/img/product-types/laser-designs/spotlight/spotlight-1.jpg');
}

.sublimation {
    background-image: url('/public/img/product-types/sublimation/spotlight/spotlight-1.jpg');
}

/* .tshirt-sublimation {} */


.product-content {
    text-align: center;
}

.product-option {
    display: flex;
    
}