.image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }
  
  .image-container {
    flex: 1;
    width: 400px; /* Full width for one image */
    height: 400px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px; /* Space between image and controls */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-container img {
    width: 100%; /* Make images responsive */
    height: 100%; /* Keeps aspect ratio */
    display: block; /* Removes bottom space on images */
    object-fit: contain;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements */
  }
