.home-welcome {
    display: flex;
    margin: 0;
    background-image: url('/public/img/home-welcome-bg.png');
    background-position: bottom left;
    background-repeat: no-repeat;
    padding: 50px;
    width: 100%;
}

.home-welcome-image { flex-grow: 1; }
.home-welcome-description { flex-grow: 0; }

.home-welcome-image img {
    border-radius: 5px;
    max-width: 900px;
}

.home-subtitle {
    font-size: 24px;
    margin: 20px;
}

.button-blue.sm {
    font-size: 80%;
}

.home-products {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.home-products-top {
    background-image: url('/public/img/home-products-top.png');
    background-position: top left;
    background-repeat: no-repeat;
    height: 53px;
    width: 100%;
}
.home-products-content {
    width: 100%;
    padding: 20px;
    background-color: #ede9a7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-products-bottom {
    background-image: url('/public/img/home-products-bottom.png');
    background-position: bottom left;
    background-repeat: no-repeat;
    height: 53px;
    width: 100%;
}

.page-product-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}