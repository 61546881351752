.the-team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mission-statement {
    max-width: 60%;
    margin: 0 auto;
}
.mission-statement p {
    margin: 10px 0;
    font-size: 16px;
}

.team-members {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}
.team-member {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 30%;
}
.team-member-image {
    border-radius: 50%;
    border: 2px dotted grey;
    background-color: #fff;
    padding: 10px;
}
/* .team-member-name {}
.team-member-bio {} */