@media screen and (max-width: 800px) {

/* show all items with red border  */

    /* * { border: 1px solid red; } */

    .site-title {
        margin: 0;
        font-size: 2.5rem;
    }

    .site-title img {
        margin: 0 10px 0 0;
    }

    .socials {
        display: none;
        visibility: hidden;
    }

    .logo-socials {
        flex-direction: column;
        gap: 2px;
        justify-content: center;
        align-items: center;
    }

    .page-content-container {
        margin: 115px 0 10px;
        max-width: 100%;
    }

    .page-header {
        margin: 0;
        padding: 0;
        font-size: 4rem;
    }

    .page-sub-header {
        font-size: 48px;
    }

    .product-header {
        font-size: 3rem;
        padding: 0;
        margin: 0;
      }
      
      .product-header span {
        font-size: 5rem;
      }

    .the-team-container {
        width: 100%;
        padding: 0 10px;
    }
    .mission-statement,
    .mission-statement p {
        max-width: 100%;
    }
    .page-container .container {
        margin: 0;
        max-width: 100%;
    }

    /*********************************************************************************************************/
    /* The Team **********************************************************************************************/
    /*********************************************************************************************************/
    .team-members {
        flex-direction: column;
    }
        .team-member {
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
        }
        .team-member:last-of-type { 
            border-width: 0px;
        }
        .team-member-details {
            display: flex;
            flex-direction: column;
            max-width: 25%;
        }
        .team-member-name {
            font-family: "Style Script", cursive;
            font-weight: 600;
            font-size: 36px;
        }
        .team-member-bio {
            text-align: left;
            padding-top: 12px;
        }
    /*********************************************************************************************************/
    /********************************************************************************************** The Team */
    /*********************************************************************************************************/

    /*********************************************************************************************************/
    /* The Tools *********************************************************************************************/
    /*********************************************************************************************************/
    .the-tools-container {
        flex-direction: column;
        max-width: 100%;
        padding: 0 10px;
    }
        .tool-details,
        .tool-details.reverse {
            flex-direction: column-reverse;
        }
        .tool-details img {
            max-width: 100%;
        }
    /*********************************************************************************************************/
    /********************************************************************************************* The Tools */
    /*********************************************************************************************************/

    /*********************************************************************************************************/
    /* Contact Form ******************************************************************************************/
    /*********************************************************************************************************/
    .contact-form {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 100%;
    }
    .contact-form img {
        width: 200px;
        margin-top: 30px;
    }
    .contact-form form {
        margin: 0;
        padding: 0;
        width:100%;
    }
    .contact-form form div:last-of-type {
        display: flex;
        justify-content: center;
    }
    .contact-form form input,
    .contact-form form textarea {
        margin: 8px 0;
    }
    /*********************************************************************************************************/
    /****************************************************************************************** Contact Form */
    /*********************************************************************************************************/


/**************/
/** Homepage **/
/**************/
.home-welcome {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 10px;
}
.home-welcome-image img {
    border-radius: 5px;
    width: 100%;
}

.home-subtitle {
    margin: 10px 0 20px;
}
.home-subtitle p {
    margin: 10px 0;
}

.filler-quote {
    padding: 0 10px;
    text-align: center;
}
.title-subline {
   margin: 10px auto 20px;
}

.socials-container {
    padding: 0 10px;
}

/************/
/* Products */
/************/
    .products-sidenav {
        display: none;
        visibility: hidden;
    }
    .product-examples {
        width: 100%;
        margin: 40px 0;
        padding: 40px 20px 0;
    }
    .product-example {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-flow: column;
    }

    .product-example.left { 
        flex-flow:column-reverse
    }

    .left .product-description { 
        justify-content: flex-start;
        text-align: left;
    }

    .product-example a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .product-image {
        max-width: unset;
        min-width: unset;
        width: 100%;
        height: 300px;
    }
 
    .product-description .page-section-header { 
        text-align: center;
    } 
    
    /* Product Item gallery */
    .product-item-gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 100px);
        grid-gap: 10px;
        overflow: hidden;
    }

    .product-item-gallery .gallery__item {
        overflow: hidden;
        background-position: center;
        background-size: cover;
    }

    .product-highlight {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-evenly;
        margin: 20px 0;
    }

    .back-button {
        background-color: #7e70fc;
        color: #fff;
        padding: 10px 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
        max-width: 100%;
    }
    
    /*****************/
    /* Footer Styles */
    /*****************/
        .footer {
            width: 100%;
            flex-direction: column-reverse;
        }

        .footer .socials {
            margin-top: 10px;
            width: 100%;
            display: flex;
            visibility: visible;
        }

        .footer a {
            justify-content: space-between;
            align-items: center;
        }

    /* New Mobile styles */
    .product-page-content {
        flex-direction: column-reverse;
        padding: 0 10px;
    }

    .nav-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 110px;
    }
    .nav.desktop {display: none; visibility: hidden; }
    .nav.mobile {
        visibility: visible;
        display: flex;
        flex-direction: column;
        color: #150e14;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding-top: 10px;
    }
    
    .nav.mobile .fa {
        margin-left: 20px;
    }
    .nav.mobile ul {
        padding: 5px 10px;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        background-color: #fff;
        border-bottom: 2px solid #dbdbdb;
    }

    .nav.mobile a {
        color: inherit;
        text-decoration: none;
        height: 100%;
        display: flex;
        padding: 0 10px;
        font-weight: 400;
        white-space: nowrap;
    }

    .social-links {
        flex-direction: column;
    }
}