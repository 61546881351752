.nav-container {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 135px;
    z-index: 99;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2);
}

.nav.mobile {
  display: none; 
  visibility: hidden; 
}
.nav.desktop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #150e14;
    align-items: stretch;
    gap: 10px;
    padding: 10px;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  }
  
  .nav.desktop ul {
    padding: 5px 10px;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-top: 2px solid #dbdbdb;
    border-bottom: 2px solid #dbdbdb;
  }
  
  .nav.desktop a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: 400;
    white-space: nowrap;
  }


  .site-title {
    font-family: "Style Script", cursive;
    font-size: 1.5rem;
    margin-right: 20px;
    text-transform: capitalize;
  }

  .site-title img {
    margin-right: 20px;
  }

  .nav li {
    font-size: 16px;
    text-transform: uppercase;
    color: #366436;
    margin: 0 20px;
    height: 100%;
  }

  .nav li.active {
    color: #ffcc00;
  }
  
  .nav li:hover,
  a:hover {
    color: #a5a5a5;
  }