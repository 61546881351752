.the-tools-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 80%;
}

.tool-details {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    font-size: 1.25em;
    border-bottom: 2px dotted grey;
    padding-bottom: 20px;
}
.tool-details.reverse {
    flex-direction: row-reverse;
}
.tool-details strong {
    font-size: 1.5em;
}
.tool-details img {
    background-color: #fff;
    padding: 10px;
    max-width: 500px;
    border-radius: 6px;
    box-shadow: 2px 2px 6px lightgrey;
}