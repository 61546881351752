* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #f8f8f3;
}

#root {
  max-width: 100%;
  max-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  padding-top: 10px;
}

/*********************/
/* Navigation Styles */
/*********************/
.logo-socials {
  display: flex;
  justify-content: space-between;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.socials a * {
  width: 25px;
  height: 25px;
}

a.external-nav-link {
  color: #150e14;
}
.link {
  color: #c19a5b;
}
.link:hover {
  color: #e4cdac;
}

.link-large {
  font-size: 1.5rem;
}

.back-button {
  background-color: #7e70fc;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  width: 220px;
}

.button-blue {
  background-color: #7e70fc;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-blue.sm {
  width: 120px;
}


/*****************/
/* Header Styles */
/*****************/
.page-header { 
    font-family: "Style Script", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 80px;
    margin: 20px;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
}

.page-sub-header {  
  font-size: 60px;
  margin: 20px;
}

.page-section-header {
  font-size: 48px;
}
.title-subline {
  background-color: #7e70fc;
  height: 2px;
  width: 100px;
}

.page-product-header {
  font-family: "Style Script", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 48px;
  margin: 10px 0;
}

.product-header {
  font-family: "Nanum Myeongjo", serif;
  font-weight: 400;
  font-style: italic;
  color: #000;
  font-size: 3rem;
  line-height: 1;
  margin: 20px;
  padding: 20px;
  text-align: center;
  width: 100%;
  border-bottom : 1px solid black;
}

.product-header span {
  color: #c19a5b;
  font-size: 5rem;
  font-style: normal;
}

.product-highlight {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  margin: 20px 0;
}


.product-highlight .product {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.product-highlight .product .product-title {
  font-family: "Style Script", cursive;
  font-size: 2.5rem;
  margin-bottom: 0;
}

.product-highlight .product .product-description {
  font-family: "Montserrat", serif;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  text-align: center;
}

.product-highlight-image {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.product-highlight-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.filler-quote {
  font-family: "Montserrat", serif;
  font-size: 2rem;
}

/*****************/
/* Layout Styles */
/*****************/
.page-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.page-container .container {
  margin: 160px 0 0;
  max-width: 1500px;
}

.page-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.external-link-icon {
  margin: 0 5px;
}

.breadcrumb-menu {
  width: 100%;
  max-width: 120;
  text-align: left;
}

.product-page-content {
  margin-top: 30px;
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/*****************/
/* Footer Styles */
/*****************/
.footer {
  background-color: #fff;
  color: #150e14;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 5px 30px;
  -webkit-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 0px -5px 5px 0px rgba(0,0,0,0.2);
  width: 100%;
  margin-top: 30px;
}
.footer .logo-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 400;
  white-space: nowrap;
}

/**********************
** Framework Styling **
**********************/

/** Margin **/
.ml-0 { margin-left: 0; }
.mr-0 { margin-right: 0; }
.mt-0 { margin-top: 0; }
.mb-0 { margin-bottom: 0; }
.mx-0 { margin-left: 0; margin-right: 0;}
.my-0 { margin-top: 0; margin-bottom: 0;}

.ml-1 { margin-left: 10px; }
.mr-1 { margin-right: 10px; }
.mt-1 { margin-top: 10px; }
.mb-1 { margin-bottom: 10px; }
.mx-1 { margin-left: 10px; margin-right: 10px;}
.my-1 { margin-top: 10px; margin-bottom: 10px;}

.ml-2 { margin-left: 20px; }
.mr-2 { margin-right: 20px; }
.mt-2 { margin-top: 20px; }
.mb-2 { margin-bottom: 20px; }
.mx-2 { margin-left: 20px; margin-right: 20px;}
.my-2 { margin-top: 20px; margin-bottom: 20px;}

.ml-3 { margin-left: 30px; }
.mr-3 { margin-right: 30px; }
.mt-3 { margin-top: 30px; }
.mb-3 { margin-bottom: 30px; }
.mx-3 { margin-left: 30px; margin-right: 30px;}
.my-3 { margin-top: 30px; margin-bottom: 30px;}

.ml-4 { margin-left: 40px; }
.mr-4 { margin-right: 40px; }
.mt-4 { margin-top: 40px; }
.mb-4 { margin-bottom: 40px; }
.mx-4 { margin-left: 40px; margin-right: 40px;}
.my-4 { margin-top: 40px; margin-bottom: 40px;}