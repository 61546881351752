@tailwind base;

.contact-form {
    display: flex;
    justify-content: center;
    width: 50%;
    max-width: 1000px;
    margin: 30px 0;
}

.contact-form form {
    width: 100%;
    margin-left: 20px;
    font-size: 16px;
}

.contact-form input, 
.contact-form textarea {
    border-width: 1px;
    border-style: solid;
    border-color: transparent transparent #ccc transparent;
    width: 100%;
    margin: 8px;
    padding: 8px;
    transition: border-color 0.3s ease;
}

.contact-form input:hover, 
.contact-form input:focus, 
.contact-form textarea:hover,
.contact-form textarea:focus {
    outline-color: #7e70fc;
    border-color: #7e70fc;
}

button[type="submit"] {
    background-color: #7e70fc;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.thank-you {
    text-align: center;
    width: 50%;
    max-width: 1000px;
}